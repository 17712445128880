import type { AuthorizationServer } from "oauth4webapi";
import { useAuthStore } from "@/stores/auth";
import * as oauth from "oauth4webapi";
import jwtDecode from 'jwt-decode';
import { type DecodedJWTType } from '@/services/loginManager';


const oidcSettings = {
    authorizationUrl: new URL(import.meta.env.VITE_OIDC_AUTHORIZATION_URL),
    clientId: import.meta.env.VITE_OIDC_CLIENT_ID,
    clientRoot: import.meta.env.VITE_OIDC_CLIENT_ROOT,
    clientScope: "openid profile email",
    codeChallengeMethod: 'S256',
}

export class OidcUserManager {
    as: AuthorizationServer | null = null;
    client: oauth.Client;

    refreshTokenInterval: number | null = null;
    readonly safetyTimeMargin = 120;

    constructor() {
        this.client = {
            client_id: oidcSettings.clientId,
            token_endpoint_auth_method: 'none',
        }
    }

    private async discoverAS(): Promise<AuthorizationServer> {
        if (this.as) {
            return this.as
        }

        const authorizationServer = await oauth
            .discoveryRequest(oidcSettings.authorizationUrl, { algorithm: "oidc" })
            .then((response) => oauth.processDiscoveryResponse(oidcSettings.authorizationUrl, response));

        this.as = authorizationServer;
        return authorizationServer;
    }

    private async refreshTokenIfNeeded() {
        const authStore = useAuthStore();

        if (authStore.accessToken) {
            let now = Date.now() / 1000
            if (authStore.refreshToken && this.needRefresh(now) && this.canRefresh(now)) {
                await this.renewToken();
            } else if (!this.canRefresh(now)) {
                this.logout();
            }
        }
    }

    private async startRefreshTokenInterval() {
        if (this.refreshTokenInterval) {
            return
        }
        console.log("start refresh token interval", new Date());
        this.refreshTokenInterval = setInterval(async () => await this.refreshTokenIfNeeded(), 3000);
        await this.refreshTokenIfNeeded();
    }

    private stopRefreshTokenInterval() {
        if (this.refreshTokenInterval) {
            console.log("stop refresh token interval", new Date());
            clearInterval(this.refreshTokenInterval);
            this.refreshTokenInterval = null;
        }
    }

    private needRefresh(now: number) {
        const authStore = useAuthStore();
        authStore.accessToken

        if (!authStore.accessToken) {
            return false;
        }

        let jwt = jwtDecode(authStore.accessToken) as DecodedJWTType;
        if (now + this.safetyTimeMargin > jwt.exp) {
            return true;
        }
        return false;
    };

    private canRefresh(now: number) {
        const authStore = useAuthStore();

        if (!authStore.refreshToken) {
            return false;
        }
        let jwt = jwtDecode(authStore.refreshToken) as DecodedJWTType;
        if (now + this.safetyTimeMargin > jwt.exp) {
            // Refresh token is valid
            return false;
        }
        return true;
    };

    public async login(): Promise<void> {
        const code_verifier = oauth.generateRandomCodeVerifier()
        const code_challenge = await oauth.calculatePKCECodeChallenge(code_verifier)
        let state: string | undefined

        const authorizationServer = await this.discoverAS();
        const authorizationUrl = new URL(authorizationServer.authorization_endpoint!)
        authorizationUrl.searchParams.set('client_id', this.client.client_id)
        authorizationUrl.searchParams.set('redirect_uri', `${oidcSettings.clientRoot}oidc/callback`)
        authorizationUrl.searchParams.set('response_type', 'code')
        authorizationUrl.searchParams.set('scope', oidcSettings.clientScope)
        authorizationUrl.searchParams.set('code_challenge', code_challenge)
        authorizationUrl.searchParams.set('code_challenge_method', oidcSettings.codeChallengeMethod)

        if (authorizationServer.code_challenge_methods_supported?.includes('S256') !== true) {
            state = oauth.generateRandomState()
            authorizationUrl.searchParams.set('state', state)
        }

        window.sessionStorage.setItem('code_verifier', code_verifier);
        window.sessionStorage.setItem('state', state || "");

        window.location.replace(authorizationUrl.href);
    }

    public async loginCallback(): Promise<void> {
        const authStore = useAuthStore();

        try {
            const authorizationServer = await this.discoverAS();

            const code_verifier = window.sessionStorage.getItem('code_verifier') || '';
            const state = window.sessionStorage.getItem('state') || undefined;

            const currentUrl: URL = new URL(window.location.href);
            const params = oauth.validateAuthResponse(authorizationServer, this.client, currentUrl, state)
            if (oauth.isOAuth2Error(params)) {
                console.error('OIDC response validation failed', params)
                throw new Error("OIDC response validation failed");
            }

            const response = await oauth.authorizationCodeGrantRequest(
                authorizationServer,
                this.client,
                params,
                `${oidcSettings.clientRoot}oidc/callback`,
                code_verifier,
            )

            const result = await oauth.processAuthorizationCodeOpenIDResponse(authorizationServer, this.client, response)
            if (oauth.isOAuth2Error(result)) {
                console.error('Unable to exchange code for JWT', result)
                throw new Error("Unable to exchange code for JWT");
            }

            authStore.setAuthTokens(result.access_token, result.refresh_token);
            await this.startRefreshTokenInterval();
            return Promise.resolve();

        } catch (exception) {
            return Promise.reject(exception);
        }
    }

    public async renewToken(): Promise<void> {
        const authStore = useAuthStore();

        if (!authStore.refreshToken) {
            return Promise.reject("No refresh token");
        }

        try {
            const authorizationServer = await this.discoverAS();
            const response = await oauth.refreshTokenGrantRequest(authorizationServer, this.client, authStore.refreshToken)

            const result = await oauth.processRefreshTokenResponse(authorizationServer, this.client, response)
            if (oauth.isOAuth2Error(result)) {
                console.error('Unable to process refresh token response', result)
                throw new Error("Unable to process refresh token response");
            }

            authStore.setAuthTokens(result.access_token, result.refresh_token);
            this.startRefreshTokenInterval();

            return Promise.resolve();
        } catch (exception) {
            return Promise.reject(exception);
        }
    }

    public logout(): Promise<void> {
        this.stopRefreshTokenInterval();

        const authStore = useAuthStore();
        authStore.setAuthTokens(null, null);
        return Promise.resolve();
    }

    public async getAccessToken() {
        const authStore = useAuthStore();
        return Promise.resolve(authStore.accessToken);
    }
}
