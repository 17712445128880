import { ref } from 'vue'
import { defineStore, acceptHMRUpdate } from 'pinia'
import { AuthBackendEnum } from '@/services/loginManager';

export const useAuthStore = defineStore('auth', () => {
    const authBackend = ref<AuthBackendEnum>(AuthBackendEnum.NONE);
    const accessToken = ref<string | null>(null);
    const refreshToken = ref<string | null>(null);

    function setAuthBackend(setAuthBackend: AuthBackendEnum) {
        authBackend.value = setAuthBackend;
    }

    function setAuthTokens(setAccessToken: string | null, setRefreshToken?: string | null) {
        accessToken.value = setAccessToken;
        if (setRefreshToken !== undefined) {
            refreshToken.value = setRefreshToken;
        }

    };

    return { accessToken, refreshToken, authBackend, setAuthTokens, setAuthBackend }
}, {
    persist: true,
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
