import { createApp, nextTick } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import * as Sentry from "@sentry/vue";

import AppWrapper from './AppWrapper.vue'
import router from './router'

// Vuetify
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { VBtn } from 'vuetify/components/VBtn'

const vuetify = createVuetify({
    aliases: {
        VBtnSecondary: VBtn,
    },

    defaults: {
        VBtn: {
            color: "primary",
        },
        VBtnSecondary: {
            color: "secondary",
        },
        VSheet: {
            elevation: 4,
        },
    },
    theme: {
        defaultTheme: 'fgiLight',
        themes: {
            fgiLight: {
                dark: false,
                colors: {
                    //background: '#E8F5E9',
                    background: '#d1e3cc',
                    surface: '#FFFFFF',
                    'surface-variant': '#424242',
                    'on-surface-variant': '#EEEEEE',
                    primary: '#4CAF50',
                    'primary-darken-1': '#43A047',
                    secondary: '#03A9F4',
                    'secondary-darken-1': '#039BE5',
                    error: '#B00020',
                    info: '#2196F3',
                    success: '#4CAF50',
                    warning: '#FB8C00'
                },
                variables: {
                    'border-color': '#000000',
                    'border-opacity': 0.12,
                    'high-emphasis-opacity': 0.87,
                    'medium-emphasis-opacity': 0.60,
                    'disabled-opacity': 0.38,
                    'idle-opacity': 0.04,
                    'hover-opacity': 0.04,
                    'focus-opacity': 0.12,
                    'selected-opacity': 0.08,
                    'activated-opacity': 0.12,
                    'pressed-opacity': 0.12,
                    'dragged-opacity': 0.08,
                    'theme-kbd': '#212529',
                    'theme-on-kbd': '#FFFFFF',
                    'theme-code': '#F5F5F5',
                    'theme-on-code': '#000000'
                }
            },
            fgiDark: {
                dark: true,
                colors: {
                    background: '#121212',
                    surface: '#212121',
                    'surface-variant': '#BDBDBD',
                    'on-surface-variant': '#424242',
                    //primary: '#BB86FC',
                    primary: '#6fe84c',
                    'primary-darken-1': '#3700B3',
                    secondary: '#03DAC5',
                    'secondary-darken-1': '#03DAC5',
                    error: '#CF6679',
                    info: '#2196F3',
                    success: '#4CAF50',
                    warning: '#FB8C00'
                },
                variables: {
                    'border-color': '#FFFFFF',
                    'border-opacity': 0.12,
                    'high-emphasis-opacity': 1,
                    'medium-emphasis-opacity': 0.70,
                    'disabled-opacity': 0.50,
                    'idle-opacity': 0.10,
                    'hover-opacity': 0.04,
                    'focus-opacity': 0.12,
                    'selected-opacity': 0.08,
                    'activated-opacity': 0.12,
                    'pressed-opacity': 0.16,
                    'dragged-opacity': 0.08,
                    'theme-kbd': '#212529',
                    'theme-on-kbd': '#FFFFFF',
                    'theme-code': '#343434',
                    'theme-on-code': '#CCCCCC'
                }
            }
        }
    }
})

const app = createApp(AppWrapper);

Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN || null,
    release: import.meta.env.VITE_SENTRY_RELEASE || "unknown",
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT || "unknown",
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", RegExp(import.meta.env.VITE_SENTRY_API_ORIGIN)],
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE || "1.0"), // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: parseFloat(import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE || "0.1"), // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(pinia)
app.use(router)
app.use(vuetify)

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $appName: string;
        $mediaBase: string
    }
}

app.config.globalProperties.$appName = "FGI:Atlas"
app.config.globalProperties.$mediaBase = import.meta.env.VITE_MEDIA_BASE_URL;

router.afterEach((to, from) => {
    nextTick(() => {
        let title = to.meta.title || ""
        if (title.length > 0) {
            title += " // "
        }
        title += app.config.globalProperties.$appName
        document.title = title
    });
});

app.mount('#app')
