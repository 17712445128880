import { computed, type Ref, ref } from 'vue';
import jwtDecode from 'jwt-decode';

import UserService, { UserPrivilegeEnum } from '@/api/users';
import { useAuthStore } from "@/stores/auth";
import { OidcUserManager } from './oidcUserManager';
import { refreshAccessToken, login as performAtlasLogin, login } from "@/api/auth";
import { useSnackStore, type IShowSnack } from '@/stores/snack';
import type { ComputedRef } from 'vue';
import { useRouter, type Router } from 'vue-router';
import { ro } from 'vuetify/locale';

export enum AuthBackendEnum {
    NONE = "",
    OIDC = "OIDC",
    ATLAS = "ATLAS",
}

type LoginManagerInstance = {
    logout: () => void;
    loginAtlas: (username: string, password: string) => Promise<void>;
    startLoginOidcFlow: () => void;
    isLogged: ComputedRef<boolean>;
    hasPrivilege: (privilege: string) => boolean;
    me: Ref<LoggedUserInfo | null>;
    processOidcCallback: () => Promise<void>;
    accessToken: ComputedRef<Promise<string | null>>;
    init: (router: Router) => Promise<void>;
};

type LoggedUserInfo = {
    id: number,
    name: string,
    email: string,
    privileges: string[],
}

export type DecodedJWTType = {
    token_type: "access" | "refresh";
    exp: number;
}

class AtlasUserManager {
    refreshTokenInterval: number | null = null;
    readonly safetyTimeMargin = 120;

    async getAccessToken() {
        const authStore = useAuthStore();
        return authStore.accessToken;
    }

    refreshTokenIfNeeded() {
        const authStore = useAuthStore();

        if (authStore.accessToken) {
            let now = Date.now() / 1000
            if (authStore.refreshToken && this.needRefresh(now) && this.canRefresh(now)) {
                refreshAccessToken(authStore.refreshToken).then((newTokens) => {
                    authStore.setAuthTokens(newTokens.access);
                });
            } else if (!this.canRefresh(now)) {
                this.logout();
            } else {
            }
        }
    }

    startRefreshTokenInterval() {
        if (this.refreshTokenInterval) {
            return
        }
        console.log("start refresh token interval", new Date());
        this.refreshTokenInterval = setInterval(() => this.refreshTokenIfNeeded(), 3000);
        this.refreshTokenIfNeeded();
    }

    stopRefreshTokenInterval() {
        if (this.refreshTokenInterval) {
            console.log("stop refresh token interval", new Date());
            clearInterval(this.refreshTokenInterval);
            this.refreshTokenInterval = null;
        }
    }

    logout() {
        this.stopRefreshTokenInterval();
        const authStore = useAuthStore();
        authStore.setAuthTokens(null, null);
    }

    needRefresh(now: number) {
        const authStore = useAuthStore();
        authStore.accessToken

        if (!authStore.accessToken) {
            return false;
        }

        let jwt = jwtDecode(authStore.accessToken) as DecodedJWTType;
        if (now + this.safetyTimeMargin > jwt.exp) {
            return true;
        }
        return false;
    };

    canRefresh(now: number) {
        const authStore = useAuthStore();

        if (!authStore.refreshToken) {
            return false;
        }
        let jwt = jwtDecode(authStore.refreshToken) as DecodedJWTType;
        if (now + this.safetyTimeMargin > jwt.exp) {
            // Refresh token is valid
            return false;
        }
        return true;
    };

    async login(username: string, password: string) {
        const authStore = useAuthStore();
        const tokens = await performAtlasLogin(username, password);
        authStore.setAuthTokens(tokens.access, tokens.refresh);
        this.startRefreshTokenInterval();
    }
}

export default (function () {
    const oidcUserManager = new OidcUserManager();
    const atlasUserManager = new AtlasUserManager();
    const isLogged = computed(() => activeUserManager.value != null);
    const accessToken = computed(() => activeUserManager.value?.getAccessToken() || Promise.resolve(null));

    let activeUserManager: Ref<OidcUserManager | AtlasUserManager | null> = ref(null);
    let me: Ref<LoggedUserInfo | null> = ref(null);

    let initialized = false;

    function logout() {
        if (activeUserManager.value) {
            activeUserManager.value.logout();
        } else {
            console.warn("You're not logged in. Unable to logout.")
        }

        forgetMe();
    }

    function forgetMe() {
        me.value = null;
        const authStore = useAuthStore();
        authStore.setAuthBackend(AuthBackendEnum.NONE);
        activeUserManager.value = null;
    }

    async function loadMe() {
        let r = (await UserService.me()).data;
        me.value = <LoggedUserInfo>{
            id: r.id,
            email: r.email,
            name: r.full_name,
            privileges: r.privileges,
        }
    }

    async function loginAtlas(username: string, password: string) {
        await atlasUserManager.login(username, password);

        const authStore = useAuthStore();
        activeUserManager.value = atlasUserManager;
        authStore.setAuthBackend(AuthBackendEnum.ATLAS);

        await loadMe();
    }

    async function startLoginOidcFlow() {
        await oidcUserManager.login();
    }

    async function processOidcCallback() {
        const snackStore = useSnackStore();
        const authStore = useAuthStore();

        try {
            await oidcUserManager.loginCallback();
            activeUserManager.value = oidcUserManager;
            authStore.setAuthBackend(AuthBackendEnum.OIDC);
            await loadMe();
        } catch (e: unknown) {
            snackStore.showSnack(<IShowSnack>{
                message: e,
                timeout: 3000,
                color: "error",
            });

            throw e;
        }
    };

    function hasPrivilege(privilege: string) {
        const i = me.value?.privileges.indexOf(privilege);
        if (i === undefined) {
            return false;
        }
        return i >= 0;
    }

    async function init(router: Router) {
        if (initialized) {
            return
        }

        const authStore = useAuthStore();

        try {
            if (authStore.authBackend == AuthBackendEnum.ATLAS) {
                activeUserManager.value = atlasUserManager;
                activeUserManager.value.startRefreshTokenInterval();

                await loadMe();
            } else if (authStore.authBackend == AuthBackendEnum.OIDC) {
                activeUserManager.value = oidcUserManager;
                try {
                    await activeUserManager.value.renewToken();
                } catch {
                    forgetMe();
                    router.push({
                        "name": "login",
                    });

                    return
                }

                await loadMe();
            }

            initialized = true;
        } catch (e: unknown) {
            console.error(e);
            forgetMe();
            router.push({
                "name": "login",
            });
        }
    }

    const instance: LoginManagerInstance = { logout, loginAtlas, startLoginOidcFlow, isLogged, hasPrivilege, me, processOidcCallback, accessToken, init };

    return () => {
        return instance;
    };
})()
