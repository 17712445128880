import { ref } from 'vue'
import { defineStore } from 'pinia'
import { reactive } from 'vue';

export const useSnackStore = defineStore('snack', () => {
    const show = ref(false);
    const snack = reactive(<IShowSnack>{
        message: "",
        timeout: 5000,
        color: ""
    });

    function showSnack(showSnack: IShowSnack) {
        snack.message = showSnack.message || "";
        snack.timeout = showSnack.timeout || 5000;
        snack.color = showSnack.color || "notice";
        show.value = true;
    }

    return { show, snack, showSnack }
})

export interface IShowSnack {
    message: string;
    timeout: number;
    color: string;
}
