import axios, { type AxiosResponse } from 'axios'
import { AxiosError } from 'axios';
import { type DjangoRestFrameworkErrorType } from '@/utils/DjangoError';
import type { FieldChoicableType } from '@/utils/FormUtils';
import useLoginManager from '@/services/loginManager';

const loginManager = useLoginManager();

export interface ApiService {
    get: (id: number) => Promise<AxiosResponse<FieldChoicableType>>;
};

export const HTTP = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    withCredentials: true,
});

// Add authorization header to every request
HTTP.interceptors.request.use(async function (config) {
    if (loginManager.isLogged.value) {
        config.headers.set("Authorization", `Bearer ${await loginManager.accessToken.value}`)
    }
    return config;
});

// Intercept response errors
HTTP.interceptors.response.use(
    (response) => response,
    (error: AxiosError<DjangoRestFrameworkErrorType>) => {
        if (error.response && error.response.data) {
            const { data } = error.response;
            // Check if the response contains the DRF error structure
            if (data.detail) {
                // Handle DRF error here, for example, show a notification or log the error
                console.error('DRF Error:', data.detail);
            }
        }
        return Promise.reject(error);
    }
);
