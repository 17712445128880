import { HTTPWithoutAuth } from "@/services/httpWithoutAuth";
import { HTTP } from "@/services/http";

export interface User {
    id: number;
    firstName: string;
}

export interface AuthTokens {
    access: string;
    refresh?: string;
}

export async function me() {
    const response = await HTTP.get<User>("/auth/users/me/");
    return {
        "data": response.data
    };
};

export async function login(email: string, password: string) {
    return HTTPWithoutAuth.post<AuthTokens>("/auth/jwt/create/", {
        "email": email,
        "password": password,
    }).then((response) => {
        return response.data;
    }).catch((error) => {
        if (error.response.status == 401) {
            return Promise.reject("Invalid username/password.")
        }
        return Promise.reject(error.message)
    });
};

export async function refreshAccessToken(refreshToken: string) {
    return HTTPWithoutAuth.post<AuthTokens>("/auth/jwt/refresh/", {
        "refresh": refreshToken,
    }).then((response) => {
        return response.data;
    }).catch((error) => {
        return Promise.reject(error.message);
    });
};
