<script setup lang="ts">
import { useSnackStore } from '@/stores/snack';

const snackStore = useSnackStore();

</script>

<template>
    <v-snackbar v-model="snackStore.show" :timeout="snackStore.snack.timeout" :color="snackStore.snack.color">
        {{ snackStore.snack.message }}
        <template v-slot:actions>
            <v-btn color="white" @click="snackStore.show = false">
                <v-icon icon="mdi-close"></v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>
