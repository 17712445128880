interface SortByType {
    key: string,
    order: "asc" | "desc",
};

export type ServerTableParamsType = {
    page: number,
    pageAfterId: number | undefined,
    itemsPerPage: number,
    sortBy: SortByType[],
    search: string,
    filter: Record<string, string>,
    direction: string,
    lte: number,
    gte: number,
    final: boolean,
};

export const DefaultServerTableParams = <DeepReadonly<ServerTableParamsType>>({
    direction: "",
    page: 1,
    pageAfterId: 0,
    itemsPerPage: 25,
    sortBy: [],
    search: "",
    filter: {},
    lte: 0,
    gte: 0,
    final: false,
});

// TODO
export const DefaultServerTableCRM = <DeepReadonly<ServerTableParamsType>>({
    direction: "",
    page: 1,
    pageAfterId: 0,
    itemsPerPage: 10,
    sortBy: [],
    search: "",
    filter: {},
    lte: 0,
    gte: 0,
    final: false,
});

export function getServerTableQuery(stParams: ServerTableParamsType) {
    let sortBy = <string[]>[];
    stParams.sortBy.forEach((x) => {
        sortBy.push((x.order == "desc" ? "-" : "") + x.key);
    });

    return {
        "direction": stParams.direction,
        "page_size": stParams.itemsPerPage,
        "page": stParams.page,
        "page_after_id": stParams.pageAfterId,
        "search": stParams.search,
        "sort": sortBy.join(","),
        "filter": generateFilterParameter(stParams.filter),
        "lte": stParams.lte,
        "gte": stParams.gte,
    }
}

export function generateFilterParameter(filterDict: Record<string, number | string>) {
    let filterBy = <string[]>[];
    if (filterDict) {
        for (const [key, value] of Object.entries(filterDict)) {
            filterBy.push(`${key}=${"" + value}`)
        }
    }

    return filterBy.join(",");
}
